import { useIsFetching } from '@tanstack/react-query'
import { useEffect } from 'react'
import styles from './LoadingBar.module.scss'

function LoadingBar() {
  const isFetching = useIsFetching()

  useEffect(() => {
    window.document.body.style.overflow = 'hidden'

    return () => {
      // @ts-ignore
      window.document.body.style.overflow = null
    }
  }, [])

  if (!isFetching) return null

  return (
    <div className={styles.container}>
      <div className={styles.container__loader}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default LoadingBar
