import React, { useRef, useEffect } from 'react'
import { useRouter } from 'next/router'
import LoadingBar from '@/features/common/components/LoadingBar'
import useRouteChangeLoader from '@/features/common/hooks/useRouteChangeLoader'
import { sendCustomEvent as gtmSendCustomEvent } from '@/utils/GTM'
import { sendEvent as kpSendEvent } from '@/utils/KakaoPixel'
import { sendEvent as npSendEvent, ConversionType } from '@/utils/NaverPremium'

type Props = React.PropsWithChildren<{}>
type PageLoadState = '' | 'LOADED' | 'ENTERED' | 'UNLOADED'

/**
 * pageEntered
 * 페이지 진입시마다 실행
 */
function pageEntered() {}

/**
 * pageLeaved
 * 페이지 떠날때마다 실행
 */
function pageLeaved() {}

/**
 * routeEntered
 * 라우트 진입시마다 실행
 *
 * @param url {string}
 */
function routeEntered(url: string) {
  // 로그 수집
  const pathname = url.startsWith('/') ? url : new URL(url).pathname
  gtmSendCustomEvent({ event: 'page_view', page_path: pathname }) // "page_location" 아님
  kpSendEvent('pageView', window.location.href)
  npSendEvent(ConversionType.INIT)
}

/**
 * routeLeaved
 * 라우트 떠날때마다 실행
 */
function routeLeaved() {}

function RouteChangeProvider({ children }: Props) {
  const router = useRouter()
  const { isRouteLoading } = useRouteChangeLoader()
  const pageLoadState = useRef<PageLoadState>('')

  // 앱 초기화 핸들러 (페이지 초기로딩시에만, 라우트 이동시에는 실행안됨)
  useEffect(() => {
    const beforeUnloadHandler = () => {
      routeLeaved()
      pageLeaved()
    }

    pageEntered()
    routeEntered(window.location.pathname)
    window.addEventListener('beforeunload', beforeUnloadHandler, { once: true })
    pageLoadState.current = 'LOADED'

    return () => {
      beforeUnloadHandler()
      pageLoadState.current = 'UNLOADED'
    }
  }, [])

  useEffect(() => {
    // 라우트 진입할 때 수행되는 핸들러
    const routeEnterHandler = (url: string) => {
      routeEntered(url)
      pageLoadState.current = 'ENTERED'
    }

    // 라우트 떠날 때 수행되는 핸들러
    const routeLeaveHandler = (_url: string) => {
      if (['LOADED', 'ENTERED'].includes(pageLoadState.current)) {
        routeLeaved()
        pageLoadState.current = ''
      }
    }

    router.events.on('routeChangeStart', routeLeaveHandler)
    router.events.on('routeChangeComplete', routeEnterHandler)

    return () => {
      router.events.off('routeChangeStart', routeLeaveHandler)
      router.events.off('routeChangeComplete', routeEnterHandler)
    }
  }, [router])

  // return null 같은 경우가 생기면 router 이동이 불가하므로 children은 반드시 출력되도록 한다.
  return (
    <>
      {isRouteLoading && <LoadingBar />}
      {children}
    </>
  )
}

export default RouteChangeProvider
