import { createContext, useMemo } from 'react'

export const EventContext = createContext<IEventModel>({ isShowPage: true, isShowModal: true })

export interface IEventModel {
  isShowPage: boolean
  isShowModal: boolean
}

function EventProvider({ children }) {
  const value = useMemo(() => ({ isShowPage: true, isShowModal: false }), [])

  return <EventContext.Provider value={value}>{children}</EventContext.Provider>
}
export default EventProvider
