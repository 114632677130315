import { DynamicLoad } from '@mornya/dynamic-load-libs'

const SDK_URL = 'https://wcs.naver.net/wcslog.js'

export enum ConversionType {
  INIT = '',
  BUY_COMPLETED = '1',
  REGISTRATED = '2',
  ADDED_CART = '3',
  APPLICATED = '4',
  ETCS = '5',
}

/**
 * sendEvent
 * 네이버 프리미엄 로그분석 로그 수집용 메소드.
 *
 * @param conversionType {ConversionType}
 * @param conversionValue {string}
 */
export function sendEvent(conversionType: ConversionType, conversionValue: string = '1'): void {
  const id = process.env.NEXT_PUBLIC_NAVER_PREMIUM_ID ?? ''

  if (id) {
    if (!window.wcs) {
      DynamicLoad.script({ id: 'np-jssdk', src: SDK_URL, referrerPolicy: 'strict-origin-when-cross-origin' })
        .then(() => {
          setTimeout(() => {
            // 공통 스크립트
            window.wcs_add = window.wcs_add ?? {}
            window._nasa = window._nasa ?? {}
            window.wcs_add['wa'] = id
            window.wcs.inflow('unban.ai') // 사이트 최상위 도메인

            if (conversionType !== ConversionType.INIT) {
              // 전환 스크립트
              window._nasa.cnv = window.wcs.cnv(conversionType, conversionValue)
              window.wcs_do(window._nasa)
            }
          }, 200)
        })
        .catch()
    } else {
      // 전환 스크립트
      window._nasa.cnv = window.wcs.cnv(conversionType, conversionValue)
      window.wcs_do(window._nasa)
    }
  }
}

const NaverPremium = {
  sendEvent,
}

export default NaverPremium
