/**
 * pushLayer
 * dataLayer에 이벤트 푸쉬
 *
 * @private
 */
function pushLayer(...args: any[]): void {
  if (typeof window !== 'undefined') {
    if (!window.dataLayer || !Array.isArray(window.dataLayer)) {
      window.dataLayer = []
    }
    window.dataLayer.push(...args)
  }
}

/**
 * initialize
 */
export function initialize(): void {
  pushLayer('js', new Date())
  pushLayer('config', process.env.NEXT_PUBLIC_GA_TRACKING_ID, {
    page_title: window.document.title, // The page's title.
    page_location: window.location.href, // The page's URL.
    page_path: window.location.pathname, // The path portion of location. This value must start with a slash (/).
  })
}

/**
 * customEvent
 * 커스텀 데이터 수집 이벤트가 들어올 때 GTM 전달을 위해 저장하는 메소드.
 *
 * @template T
 * @param args {[T, ...any]}
 */
export function sendCustomEvent<T = any>(...args: [T, ...any]): void {
  // 해당 메소드를 사용하는 쪽의 props에서 직접받아 처리하는 경우 undefined 일 수 있으므로 예외 처리
  if (args?.length > 0) {
    pushLayer(...args)
  }
}

/**
 * clearAllEvents
 * 수집 대상 커스텀 데이터를 모두 제거.
 */
export function clearAllEvents(): void {
  if (typeof window !== 'undefined') {
    window.dataLayer = []
  }
}

const GTM = {
  initialize,
  sendCustomEvent,
  clearAllEvents,
}

export default GTM
