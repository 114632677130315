import axios from 'axios'
import type { AxiosInstance, AxiosError, AxiosResponse as BaseAxiosResponse, InternalAxiosRequestConfig } from 'axios'
import React, { createContext, useContext, useRef, useState, useEffect } from 'react'
import * as Events from '@/utils/events'

interface IProps
  extends React.PropsWithChildren<{
    onRequest?(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>
    onRequestError?(error: AxiosError): void
    onResponse?(response: BaseAxiosResponse): BaseAxiosResponse | Promise<BaseAxiosResponse>
    onResponseError?(error: AxiosError): void
  }> {}

type FetcherContextValue = {
  axiosInstance: AxiosInstance
  isTokenReady: boolean
}

const FetcherContext = createContext<FetcherContextValue>({
  axiosInstance: axios,
  isTokenReady: false,
})

export function FetcherProvider(props: IProps) {
  const availableRequests = useRef<number>(0)
  const [nativeAccessToken, setNativeAccessToken] = useState<string>('')

  axios.defaults.headers.common = { 'Content-Type': 'application/json-patch+json' }
  axios.defaults.method = 'get'
  axios.defaults.withCredentials = false // true 일 경우 CORS 에러발생
  axios.defaults.timeout = 300000
  axios.defaults.baseURL = process.env.NEXT_PUBLIC_SERVICE_API_URL
  axios.interceptors.request.use()
  axios.interceptors.response.use()

  const axiosInstance = axios.create()

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    config => {
      // 헤더 정보에 "disable-loading" 포함되어 있을 경우 로딩 인디케이터를 띄우지 않음
      if (!config.headers.get('disable-loading')) {
        Events.emit('fetcher/LOADING', true)
      }

      const accessToken = window.sessionStorage.getItem('accessToken')
      if (accessToken) {
        config.headers.set('Authorization', `Bearer ${accessToken}`)
      }

      return props.onRequest?.(config) ?? config
    },
    error => {
      Events.emit('fetcher/LOADING', false)
      props.onRequestError?.(error)
      return Promise.reject(error)
    },
  )

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      Events.emit('fetcher/LOADING', false)
      return props.onResponse?.(response) ?? response
    },
    async error => {
      Events.emit('fetcher/LOADING', false)
      props.onResponseError?.(error)
      return Promise.reject(error)
    },
  )

  useEffect(() => {
    const loadingHandler = Events.create<boolean>(flag => {
      availableRequests.current = availableRequests.current + (flag ? 1 : -1)
      if (availableRequests.current < 0) {
        availableRequests.current = 0
      }
    })
    const receivedTokenHandler = Events.create<string>(accessToken => {
      setNativeAccessToken(accessToken)
    })

    Events.on('fetcher/LOADING', loadingHandler)
    Events.on('fetcher/RECEIVED_TOKEN', receivedTokenHandler)

    return () => {
      Events.off('fetcher/LOADING', loadingHandler)
      Events.off('fetcher/RECEIVED_TOKEN', receivedTokenHandler)
    }
  }, [])

  return (
    <FetcherContext.Provider value={{ axiosInstance, isTokenReady: !!nativeAccessToken }}>
      {props.children}
    </FetcherContext.Provider>
  )
}

export function useFetcher() {
  return useContext(FetcherContext)
}

export default FetcherContext
