import { DynamicLoad } from '@mornya/dynamic-load-libs'

const SDK_URL = 'https://t1.daumcdn.net/kas/static/kp.js'

/**
 * sendEvent
 * 카카오 픽셀 로그 수집용 메소드.
 *
 * @template T
 * @param event {T}
 * @param params {ArgumentTypes<kakao.Pixel.Event[typeof event]>}
 */
export function sendEvent<T extends keyof kakao.Pixel.Event>(
  event: T,
  params?: ArgumentType<kakao.Pixel.Event[typeof event]>,
): void {
  const id = process.env.NEXT_PUBLIC_KAKAO_PIXEL_ID ?? ''

  if (id) {
    DynamicLoad.script({ id: 'kp-jssdk', src: SDK_URL, referrerPolicy: 'strict-origin-when-cross-origin' })
      .then(() => {
        let count = 0
        const tid = setInterval(() => {
          count += 1
          if (!!window.kakaoPixel || count > 5) {
            clearInterval(tid)
          }
          window.kakaoPixel?.(id)[event as string](params)
        }, 100)
      })
      .catch()
  }
}

const KakaoPixel = {
  sendEvent,
}

export default KakaoPixel
