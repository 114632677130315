/*
 * Events module.
 * 광역이벤트 등록 및 파라미터 전달을 위한 커스텀 이벤트 리스너
 */
export type Listener<T = any> = (detail: T) => void
export type ListenerOption = {
  stopPropagation: boolean
}

const defaultListenerOption: ListenerOption = {
  stopPropagation: false,
}

/**
 * create
 * 이벤트 등록을 위한 핸들러(리스너)를 생성한다.
 *
 * @template EventDetail
 * @param listener {Listener<EventDetail>}
 * @param listenerOption {ListenerOption | undefined}
 * @returns {EventListener}
 * @private
 */
export function create<EventDetail = any>(
  listener: Listener<EventDetail>,
  listenerOption?: ListenerOption,
): EventListener {
  return event => {
    const option = { ...defaultListenerOption, ...listenerOption }

    if (option.stopPropagation) {
      // 동일 종류의 이벤트까지 무효 처리.
      // 동일 이벤트명으로 중복 등록된 이벤트를 모두 실행시키기 위해서는
      // stopImmediatePropagation이 실행되지 않도록 옵션 처리해 준다.
      event.stopImmediatePropagation()
    }

    listener((event as CustomEvent<EventDetail>).detail)
  }
}

/**
 * on
 * Events.create 메소드로 생성한 커스텀 이벤트를 리스너로 등록한다.
 *
 * @template EventDetail
 * @param type {string}
 * @param createdEventListener {Listener<CustomEvent<EventDetail>>}
 */
export function on<EventDetail = any>(type: string, createdEventListener: Listener<CustomEvent<EventDetail>>): void {
  window.addEventListener(type, createdEventListener as EventListener, false)
}

/**
 * once
 * Events.create 메소드로 생성한 커스텀 이벤트를 리스너로 등록한다 (1회 실행 후 해제).
 *
 * @template EventDetail
 * @param type {string}
 * @param createdEventListener {Listener<CustomEvent<EventDetail>>}
 */
export function once<EventDetail = any>(type: string, createdEventListener: Listener<CustomEvent<EventDetail>>): void {
  window.addEventListener(type, createdEventListener as EventListener, { once: true })
}

/**
 * off
 * Events.create 메소드로 생성 및 등록한 리스너를 커스텀 이벤트에서 해제한다.
 *
 * @template EventDetail
 * @param type {string}
 * @param createdEventListener {Listener<CustomEvent<EventDetail>> | EventListener}
 */
export function off<EventDetail = any>(
  type: string,
  createdEventListener: Listener<CustomEvent<EventDetail>> | EventListener,
): void {
  window.removeEventListener(type, createdEventListener as EventListener, false)
}

/**
 * emit
 * 이벤트를 발생시켜 파라미터(detail)을 전파한다.
 *
 * @template EventDetail
 * @param type {string}
 * @param detail {EventDetail | undefined}
 */
export function emit<EventDetail = any>(type: string, detail?: EventDetail): void {
  window.dispatchEvent(new CustomEvent<EventDetail>(type, { detail }))
}
