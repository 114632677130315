import { useEffect, useState } from 'react'
import { useRouter } from 'next/dist/client/router'

const useRouteChangeLoader = () => {
  const router = useRouter()
  const [isRouteLoading, setIsRouteLoading] = useState(false)

  useEffect(() => {
    router.events.on('routeChangeComplete', () => setIsRouteLoading(false))
    return () => {
      router.events.off('routeChangeComplete', () => setIsRouteLoading(false))
    }
  }, [router.events])

  useEffect(() => {
    router.events.on('routeChangeStart', async () => {
      setIsRouteLoading(true)
    })
    return () => {
      router.events.off('routeChangeStart', () => setIsRouteLoading(true))
    }
  }, [router.events])

  return {
    isRouteLoading,
  }
}

export default useRouteChangeLoader
